import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query($slug: String!) {
    post: contentfulPost(slug: { eq: $slug }) {
      id
      name
      slug
      date(formatString: "DD MMM YYYY", locale: "fr")
      image {
        title
        description
        file {
          url
        }
      }
      body {
        raw
      }
    }
  }
`
const Post = props => {
  return (
    <Layout>
      <SEO title={props.data.post.name} />
      <div className="post-date">{props.data.post.date}</div>

      <div className="post-title medium">{props.data.post.name}</div>

      <div
        className="post-image"
        style={{
          backgroundImage: "url(" + props.data.post.image.file.url + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "auto",
          filter: "saturate(1.3)",
        }}>  
        </div>
        
      <div className="post-image-details">
        <div className="medium one-line"> {props.data.post.image.title}</div>
        <div className="one-line"> {props.data.post.image.description}</div>
      </div>
      <div className="post-body">
        {documentToReactComponents(JSON.parse(props.data.post.body.raw))}
      </div>
    </Layout>
  )
}

export default Post
